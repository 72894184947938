.activeStudent{
    display: flex !important;
}
.hideStudent {
    display: none !important;
}
.OT_publisher, .OT_subscriber {
    position: relative;
    min-width: 30px;
    min-height: 200px !important;
}
