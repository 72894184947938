.mainArea {
  margin-bottom: 0;
  flex-grow: 1;
  height: 100%;
  margin-top: 0;
  overflow: hidden;
}

.publisher_video {
  /* height: 100vh; */
}

.subscriber_video {
  height: 200px;

}

.subscriber_video .video_thum {
  height: 150px;
}

.video-left {
  position: relative;
}

ButtonGroup {
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  z-index: 99;
  width: 100%;
}

.video_group_btn {
  width: 100%;
}

.hidden {
  display: none;
}

.logo-tag-line {
  font-size: 10px;
}

.dip-virtual-class>div {
  height: 100%;
}

.OTPublisherContainer {
  height: 100% !important;
  width: 100% !important;
}