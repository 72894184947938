.TeacherPanel {
  height: 100%;
  width: 100%;
  position: relative;
}

.TeacherPanel>div {
  width: 100% !important;
  height: 100% !important;
}

.TeacherPanel::before {
  content: 'Teacher will join soon';
  position: absolute;
  position: absolute;
  left: 39%;
  top: 45%;
  font-weight: bold;
  font-size: 1.5rem;
}