:root {
    --midBlackBG: #231F20;
    --greyBg: #CCCCCC;
    --orange: #F58220;
    --lightclr: #FB943C;

}
    

.MuiImageListItemBar-positionBelow {background:var(--lightclr) !important; color: #fff !important;}
.MuiImageList-standard {column-gap: 10px !important; padding-left: 15px !important; padding-right: 15px !important; margin-bottom: 50px; overflow: hidden; height: 100% !important; margin-top: 10px;}
.activeStudent {border: 10px solid  var(--lightclr) !important; background-color: var(--lightclr) !important; cursor: pointer !important; transition: 0.5s;  margin-bottom: 10px;}
.activeStudent:hover {border-color: var(--orange) !important; background-color: var(--orange) !important;}
.activeStudent:hover .MuiImageListItemBar-positionBelow {background-color: var(--orange) !important;}

.teacher_TeacherPanel__M2CB5::before {position: inherit !important; left: inherit !important; top: inherit !important; display: flex !important; align-items: center !important; justify-content: center !important; width: 100% !important; height: 100% !important;}

.MuiSvgIcon-fontSizeMedium {display: none !important;}
.backBtn {background-color: var(--midBlackBG); border-radius: 12px; padding: 15px 20px; margin-bottom: 2px; background-image: url("../public/images/leftArrow.svg"); background-repeat: no-repeat; background-position: center; cursor: pointer;}
.css-oa138a {margin-bottom: 35px !important;}
.teacher_TeacherPanel__glhZZ::before {position: initial !important; left: inherit !important; top: inherit !important; display: flex !important; align-items: center !important; justify-content: center !important; width: 100% !important; height: 100% !important;}
.css-1lujp9z-MuiButtonBase-root-MuiIconButton-root {padding-top: 12px !important;}
.css-13kzxs8-MuiImageListItemBar-titleWrap {padding-top: 12px !important;}

/* Responsive css */
@media screen and (max-width:1199px){

.main {
    grid-template-columns:repeat(3, 1fr) !important;
}

}

@media screen and (max-width:767px){

    .main  {
        grid-template-columns:repeat(2, 1fr) !important;
    }
    
    }
